import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

const Listing = ({ pageData }) => {
  return (
    <section className="install-listing">
      <div className="container-fluid">
        <motion.h2 {...fadeAnimation}>
          {pageData?.acfInstall?.specialistsHeader}
        </motion.h2>
        <motion.div className="install-listing__table" {...fadeAnimation}>
          <div className="install-listing__table-header">
            <div>
              <strong>Firma</strong>
            </div>
            <div>
              <strong>Obszar realizacji</strong>
            </div>
            <div>
              <strong>Numer telefonu</strong>
            </div>
            <div>
              <strong>Adres email</strong>
            </div>
          </div>
          {pageData?.acfInstall?.specialistsContent?.map((item, index) => (
            <div className="install-listing__table-item" key={index}>
              <div>
                <address
                  dangerouslySetInnerHTML={{ __html: item?.company }}
                ></address>
              </div>
              <div>
                <p>{item?.region}</p>
              </div>
              <div>
                <a href={`tel:${item?.phone}`}>{item?.phone}</a>
              </div>
              <div>
                <a href={`mailto:${item?.email}`}>{item?.email}</a>
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}

export default Listing
