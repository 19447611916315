import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import fadeAnimation from "utils/fadeAnimation"

import IcoDownload from "assets/icons/download.svg"

const Download = ({ pageData }) => {
  return (
    <section className="install-download">
      <div className="container-fluid">
        <motion.h2 {...fadeAnimation}>
          {pageData?.acfInstall?.filesHeader}
        </motion.h2>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <motion.a
              href="https://cms.wallcraft.com.pl/wp-content/uploads/2024/02/instrukcja-montazu.pdf"
              className="install-download__item"
              target="_blank"
              rel="noreferrer"
              {...fadeAnimation}
            >
              <img src={IcoDownload} alt="" />
              <p>Instrukcja montażu tapety</p>
            </motion.a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Download
