import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import BanerContact from "components/BanerContact"

import { Content, Listing, Download } from "page_components/install"

const Install = ({ data }) => {
  const pageData = data?.allWpPage?.nodes[0]

  return (
    <Layout>
      <Breadcrumbs title="Montaż" />
      <PageHeader
        title={pageData?.acfInstall?.header}
        content={pageData?.acfInstall?.subHeader}
      />
      <Content pageData={pageData} />
      <Listing pageData={pageData} />
      <Download pageData={pageData} />
      <BanerContact />
    </Layout>
  )
}

export const Head = ({ data }) => (
  <Seo
    title={
      data?.allWpPage?.nodes[0]?.seo?.title
        ? data?.allWpPage?.nodes[0]?.seo?.title
        : data?.allWpPage?.nodes[0]?.title
    }
    description={
      data?.allWpPage?.nodes[0]?.seo?.metaDesc &&
      data?.allWpPage?.nodes[0]?.seo?.metaDesc
    }
  />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo3Mzc1" } }) {
      nodes {
        seo {
          title
          metaDesc
        }
        acfInstall {
          header
          subHeader
          content
          contentImage {
            localFile {
              publicURL
            }
          }
          specialistsHeader
          specialistsContent {
            company
            region
            phone
            email
          }
          filesHeader
          filesContent {
            name
            file {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default Install
